.hamburger {
	display: none !important;
}
.navigation-menu li {
	display: inline;
	white-space: nowrap;
}

@font-face {
	font-family: 'BadScript';
	src: url('/public/fonts/Bad_Script/badscript-regular-webfont.woff2') format('woff2'),
		url('/public/fonts/Bad_Script/badscript-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'BadScript2';
	src: url('/public/fonts/Bad_Script/BadScript-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

@media screen and (max-width: 768px) {
	.hamburger {
		display: flex !important;
	}
	.navigation-menu ul {
		border-radius: 8px;
		display: none !important;
	}
	.navigation-menu.expanded .ulContainer {
		position: fixed;
		top: 0;
		left: 0;
		display: flex !important;
		margin-top: 69px;
		width: 100%;
	}
	.navigation-menu.expanded ul {
		border: 1px solid rgba(106, 106, 106, 0.4);
		background: #fff;
		width: 100%;
		display: flex !important;
		height: 60px;
		justify-content: space-between;
		align-items: center;
		margin: 0 2rem;
	}
	@media (max-width: 600px) {
		.navigation-menu.expanded ul {
			margin: 0;
		}
	}
	.navigation-menu ul button {
		border: none;
	}
	.navigation-menu li a {
		color: #252525;
	}
}

@font-face {
	font-family: Figtree;
	font-weight: 500;
	src: url('/public/fonts/Figtree//Figtree-Medium.ttf'));
}


@font-face {
	font-family: Figtree;
	font-weight: 600;
	src: url('/public/fonts/Figtree/Figtree-SemiBold.ttf'));
}

@font-face {
	font-family: Figtree;
	font-weight: 700;
	src: url('/public/fonts/Figtree/Figtree-Bold.ttf'));
}

@font-face {
	font-family: Figtree;
	font-weight: 900;
	src: url('/public/fonts/Figtree/Figtree-Black.ttf'));
}

html {
	--scroll-behavior: smooth;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: FigTree, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	text-decoration: none !important;
}

input {
	outline: none;
}



.home .panel {
	width: 600px;
	display: flex;
	justify-content: center;
	margin: 30px auto;
}

.home .panel a {
	width: 100%;
	text-align: center;
	display: inline-block;
	padding: 30px;
	box-shadow: #333 0 0 10px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	color: #333;
}

.home .panel a:hover {
	color: #fff;
	background: #333;
}

.login-form,
.register-form,
.profile {
	display: flex;
	flex-wrap: wrap;
	width: 400px;
	margin: 30px auto;
	padding: 30px 60px;
	box-shadow: #333 0 0 10px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
}

.login-form label,
.register-form label,
.profile label {
	display: block;
	width: 100%;
	padding: 5px;
}

.login-form input,
.register-form input {
	display: block;
	width: 100%;
	padding: 10px;
	border: 1px solid #333;
	background: #fff;
}

.login-form button,
.register-form button {
	display: inline-block;
	margin-top: 15px;
	padding: 10px 20px;
	border: 1px solid #333;
	background: #333;
	color: #fff;
	cursor: pointer;
}

.login-form button:hover,
.register-form button:hover {
	background: #fff;
	color: #333;
}

.login-form a,
.register-form a,
.profile a {
	display: block;
	width: 100%;
	margin-top: 30px;
	color: #ec8313;
	font-weight: 700;
}

@font-face {
	font-family: 'BadScript';
	src: url('/public/fonts/Bad_Script/badscript-regular-webfont.woff2') format('woff2'),
		url('/public/fonts/Bad_Script/badscript-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'BadScript2';
	src: url('/public/fonts/Bad_Script/BadScript-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

.container {
	height: 100%;
	position: relative;
	/* text-align: center; */
	width: auto;
	font-size: 0.875rem;
	padding: 20px 0 20px;
	border: 1px solid lightgrey;
	border-radius: 12px;
	box-shadow: 0px 4px 30px lightgrey;
}

.vertical-center {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.postit-bg {
	background-color: #fffbe6;
}
.thankyoubg {
	margin: 0;
	padding: 0;
	background: url('/public/media/988283_OHGDP40.png') no-repeat center fixed; /* pour anciens Chrome et Safari */
	background-size: cover; /* version standardisée */
}

.fixed-centered-position {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1;
}

.MuiDrawer-paper{
	border-radius: 8px 8px 0px 0px;
	background-color: #F6F5FF!important;
	height: 80%;
}

.hamburger {
	display: none !important;
}
.navigation-menu li {
	display: inline;
	white-space: nowrap;
}

@font-face {
	font-family: 'BadScript';
	src: url('/public/fonts/Bad_Script/badscript-regular-webfont.woff2') format('woff2'),
		url('/public/fonts/Bad_Script/badscript-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'BadScript2';
	src: url('/public/fonts/Bad_Script/BadScript-Regular.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

@media screen and (max-width: 768px) {
	.hamburger {
		display: flex !important;
	}
	.navigation-menu ul {
		border-radius: 8px;
		display: none !important;
	}
	.navigation-menu.expanded .ulContainer {
		position: fixed;
		top: 0;
		left: 0;
		display: flex !important;
		margin-top: 69px;
		width: 100%;
	}
	.navigation-menu.expanded ul {
		border: 1px solid rgba(106, 106, 106, 0.4);
		background: #fff;
		width: 100%;
		display: flex !important;
		height: 60px;
		justify-content: space-between;
		align-items: center;
		margin: 0 2rem;
	}
	@media (max-width: 600px) {
		.navigation-menu.expanded ul {
			margin: 0;
		}
	}
	.navigation-menu ul button {
		border: none;
	}
	.navigation-menu li a {
		color: #252525;
	}
}

